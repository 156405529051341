import React, { useState } from 'react';
import { FaTimes } from 'react-icons/fa';

const EnlargerModal = ({ children }) => {
    const [open, setOpen] = useState(false);
    return (
        <div className="enlarger-modal">
            <button className="remove-button-styles enlarger-modal__item" onClick={() => setOpen(true)}>
                {children}
            </button>
            {open && <div className="enlarger-modal__modal">
                <button className="remove-button-styles enlarger-modal__modal__bg" onClick={() => setOpen(false)} />
                <div className="enlarger-modal__modal__item">{React.cloneElement(children, { className: '' })}</div>
                <button className="enlarger-modal__modal__exit-btn remove-button-styles"
                    onClick={() => setOpen(false)}>
                    <FaTimes />
                </button>
            </div>}
        </div>
    )
}

export default EnlargerModal;
import React from "react";

import { graphql } from "gatsby";
import Img from "gatsby-image";

import Layout from "../layout";
import TitleHeader from "../layout/title-header";

import SEO from "../components/seo";
import EnlargerModel from '../components/enlarger-modal';
import FileLink from '../components/file-link';

const Article = ({ data }) => {
    const { title, body } = data.prismicArticle.data;
    const mainImgSlice = body.find(({ slice_type }) => slice_type === "image");
    const firstTextSlice = body.find(({ slice_type }) => slice_type === "text");
    return (
        //TODO: add keywords to prismic
        <Layout lang={data.prismicArticle.lang}>
            <SEO
                title={title}
                lang={data.prismicArticle.lang}
                description={firstTextSlice ? firstTextSlice.primary.text.text : ''}
                image={mainImgSlice ? mainImgSlice.primary.image.url : null}
            />
            <main id="article-page">
                {title && <TitleHeader>{title}</TitleHeader>}
                <article className="article-content responsive-paddings--vertical">
                    {
                        body.map(({ slice_type, primary, items }, i) => {
                            if (slice_type === "text")
                                return <div
                                    className="article-content__text responsive-margins--horizontal"
                                    dangerouslySetInnerHTML={{ __html: primary.text.html }}
                                    key={i} />;
                            if (slice_type === "image") {
                                let className = "article-content__img ";
                                if (primary.is_main !== "yes") {
                                    className += "responsive-margins--horizontal";
                                } else {
                                    className += "article-content__img--main";
                                }
                                return (
                                    <div className="article-content__image" key={i}>
                                        <EnlargerModel>
                                            {primary.image.localFile.childImageSharp ?
                                                <Img
                                                    fluid={primary.image.localFile.childImageSharp.fluid}
                                                    objectFit="contain"
                                                />
                                                :
                                                <img className={className}
                                                    src={primary.image.url}
                                                    alt={primary.image.alt}
                                                />
                                            }
                                        </EnlargerModel>
                                    </div>
                                )
                            }
                            if (slice_type === "file") {
                                return (
                                    <div key={i} className="article-content__file responsive-margins--horizontal">
                                        <FileLink src={primary.link.url} fileName={primary.name} />
                                    </div>
                                )
                            }
                            if (slice_type === "table") {
                                return (
                                    <table className="article-content__table responsive-margins--horizontal">
                                        {items.map(({ left_item, right_item }) => (
                                            <tr key={left_item.html}>
                                                <td dangerouslySetInnerHTML={{ __html: left_item.html }} />
                                                <td dangerouslySetInnerHTML={{ __html: right_item.html }} />
                                            </tr>
                                        ))}
                                    </table>
                                )
                            }
                            if (slice_type === "embed")
                                return <div
                                    className="article-content__embed responsive-margins--horizontal"
                                    dangerouslySetInnerHTML={{ __html: primary.embed.text }}
                                    key={i} />;
                            return "wrong content";
                        })
                    }
                </article>
            </main>
        </Layout>
    )
}
export default Article;

export const query = graphql`
    query($slug: String!) {
        prismicArticle (fields: { slug: { eq: $slug } }){
            lang
            data {
                title
                body {
                    ... on PrismicArticleBodyText {
                        slice_type
                        primary {
                            text {
                                html
                                text
                            }
                        }
                    }
                    ... on PrismicArticleBodyImage {
                        slice_type
                        primary {
                            image {
                                url
                                alt
                                localFile {
                                    childImageSharp {
                                        fluid(quality: 100) {
                                            ...GatsbyImageSharpFluid_withWebp
                                        }
                                    }
                                }
                            }
                            is_main
                        }
                    }
                    ... on PrismicArticleBodyFile {
                        slice_type
                        primary {
                            name
                            link {
                                url
                            }
                        }
                    }
                    ... on PrismicArticleBodyTable {
                        slice_type
                        items {
                            left_item {
                                html
                            }
                            right_item {
                                html
                            }
                        }
                    }
                    ... on PrismicArticleBodyEmbed {
                        slice_type
                        primary {
                            embed {
                                text
                            }
                        }
                    }
                }
            }
        }
    }
`
import React from 'react';
import { FaFileDownload } from 'react-icons/fa';

const FileLink = ({ src, fileName }) => (
    <a className="file-link"
        title="download file"
        aria-label="download file"
        target="_blank"
        rel="noopener noreferrer"
        href={src}>
        <span className="file-link__icon">
            <FaFileDownload />
        </span>
        <span className="file-link__name">
            {`${fileName || src}`}
        </span>
    </a>
)

export default FileLink;